import './assets/main.css'
import '@/assets/fonts/fonts.css' // Import the global stylesheet
import 'vue-final-modal/style.css' // vue-final-modal 4
import '@/assets/fonts/fonts.css'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import { createVfm } from 'vue-final-modal'
import VueApexCharts from 'vue3-apexcharts'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
const pinia = createPinia()

import App from './App.vue'
import { router } from './router'
import { useAuthUserStore } from './stores/auth-store' // Import the auth store

const app = createApp(App)
const vfm = createVfm()

app.use(IonicVue)
app.use(router)
app.use(pinia)
app.use(VueApexCharts)
app.use(vfm)

const authStore = useAuthUserStore()
authStore.setRouter(router) // Set the router instance

router.isReady().then(() => {
  app.mount('#app')
})
