import FileUploadAndroid from '@/plugins/android/FileUploadPlugin'
import FileUploadIOS from '@/plugins/ios/FileUploadPlugin'
import * as httpClient from './capacitor-http-client'
import { isPlatform } from '@ionic/vue'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'

const baseUrl = '/file/upload/'

const store = async (videoFilePath: string, thumbnail: string, assetType: string, attemptId: string) => {
  if (isPlatform('mobileweb') || isPlatform('desktop')) {
    await uploadFileWeb(videoFilePath, thumbnail, assetType, attemptId)
  } else {
    const FileUpload = isPlatform('ios') ? FileUploadIOS : FileUploadAndroid
    const result = await SecureStoragePlugin.get({ key: 'token' })
    await FileUpload.uploadFile({
      filePath: videoFilePath,
      thumbnail: thumbnail,
      token: result.value,
      url: 'https://demo.fullathlete.com/api/file/upload/video',
      assetType: assetType || '',
      attemptId: attemptId || '',
    })
  }
}

const uploadFileWeb = async (
  videoFilePath: string,
  thumbnailFilePath: string,
  assetType: string,
  attemptId: string
) => {
  // Use uploadFileWeb for web platforms
  const videoFile = new File([await fetch(videoFilePath).then((r) => r.blob())], 'video.mp4')

  // Ensure the base64 string is properly formatted
  const base64String = thumbnailFilePath.split(',')[1] || thumbnailFilePath
  const byteCharacters = atob(base64String)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const thumbnailBlob = new Blob([byteArray], { type: 'image/jpeg' })
  const thumbnailFile = new File([thumbnailBlob], 'thumbnail.jpg')
  const formData = new FormData()
  formData.append('asset_type', assetType)
  formData.append('attempt_id', attemptId)
  formData.append('file', videoFile)
  formData.append('thumbnail', thumbnailFile)

  return await httpClient.postFormData(baseUrl + 'video', formData)
}

const storeProfilePicture = async (file: File, assetType: string) => {
  if (isPlatform('mobileweb') || isPlatform('desktop')) {
    const formData = new FormData()
    if (file) {
      formData.append('asset_type', assetType)
      formData.append('file', file)
    }

    return await httpClient.postFormData(baseUrl + 'profile-picture', formData)
  } else {
    const FileUpload = isPlatform('ios') ? FileUploadIOS : FileUploadAndroid
    const result = await SecureStoragePlugin.get({ key: 'token' })

    // Convert File to base64
    const base64File = await fileToBase64(file)

    try {
      await FileUpload.uploadProfilePicture({
        file: base64File,
        token: result.value,
        url: 'https://demo.fullathlete.com/api/file/upload/profile-picture',
        assetType: assetType,
      })
    } catch (error) {
      console.error('Error uploading profile picture:', error)
      throw error
    }
  }
}

// Helper function to convert File to base64
const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer
      const bytes = new Uint8Array(arrayBuffer)
      const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '')
      const base64 = btoa(binary)
      resolve(base64)
    }
    reader.onerror = (error) => reject(error)
  })
}

const show = () => {
  return httpClient.get(baseUrl)
}

const fileService = {
  store,
  storeProfilePicture,
  show,
}
export default fileService
