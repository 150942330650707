import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface State {
  loadingStates: Record<string, boolean>
}

export const useLoadingStore = defineStore('loading', () => {
  const loadingStates = ref<Record<string, boolean>>({})

  const toggleLoading = (key: string, active?: boolean) => {
    loadingStates.value[key] = active ?? !loadingStates.value[key]
  }

  const isLoading = (states: string[]): boolean => {
    return states.some((state) => loadingStates.value[state as keyof typeof loadingStates.value])
  }

  return { loadingStates, toggleLoading, isLoading }
})
