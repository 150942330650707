import * as httpClient from './capacitor-http-client'
import type { User } from '@/types/User'

export interface Credentials {
  email: string
  name?: string
  password?: string
  phone_number?: string
}

const login = (payload: Credentials) => httpClient.post<User>('/auth/login', payload).then((response) => response.data)

const signup = (payload: Credentials) =>
  httpClient.post<User>('/auth/signup', payload).then((response) => response.data)

const logout = () => httpClient.post('/auth/logout')

const verifyPin = (pin: string) => httpClient.post('/auth/verify', { pin })

const resendPin = () => httpClient.get('/auth/verify/resend')

const authService = { login, signup, logout, verifyPin, resendPin }

export default authService
