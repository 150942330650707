import authService, { type Credentials } from '../services/auth-service'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading-store'
import { computed, reactive, toRefs } from 'vue'
import { useToastStore } from './toast-store'
import type { User } from '../types/User'
import type { Router } from 'vue-router'
import { useUserStore } from './user-store'
import { resetAllStores } from '@/helpers/store-helpers' // Adjust the import path as needed

export const useAuthUserStore = defineStore('auth-store', () => {
  const loading = useLoadingStore()
  const toast = useToastStore()
  const userStore = useUserStore()
  let routerInstance: Router | null = null

  const getInitialState = () => ({
    authUser: undefined as User | undefined,
    isAuthenticated: false,
  })

  const state = reactive(getInitialState())

  const profileUsername = computed(() =>
    state.authUser ? `${state.authUser.first_name} ${state.authUser.last_name}` : ''
  )

  const setRouter = (router: Router) => {
    routerInstance = router as any
  }

  const login = async (payload: Credentials) => {
    loading.toggleLoading('auth-login-loading')
    try {
      const token = await authService.login(payload)
      SecureStoragePlugin.set({ key: 'token', value: token.data })
      await userStore.me()
      if (routerInstance) {
        await routerInstance.push({ path: '/fitness-activity' })
      }
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('auth-login-loading')
    }
  }

  const signup = async (payload: Credentials) => {
    loading.toggleLoading('auth-register-loading')
    try {
      const token = await authService.signup(payload)
      SecureStoragePlugin.set({ key: 'token', value: token.data })
      toast.displaySuccess('Success!')
      await userStore.me()
      if (routerInstance) {
        await routerInstance.push({ path: '/onboarding' })
      }
      return true
    } catch (error) {
      toast.displayError(error)
      return error
    } finally {
      loading.toggleLoading('auth-register-loading')
    }
  }

  const logout = async () => {
    try {
      await authService.logout()
      await SecureStoragePlugin.remove({ key: 'token' })
      state.isAuthenticated = false
      resetAllStores()
      if (routerInstance) {
        await routerInstance.replace('/login')
      }
    } catch (error) {
      toast.displayError(error)
    }
  }

  const verifyPin = async (payload: string) => {
    try {
      await authService.verifyPin(payload)
      toast.displaySuccess('Thank you, Token verified!', 2500)
      return true
    } catch (error) {
      toast.displayError(error)
    }
  }

  const resendPin = async () => {
    try {
      await authService.resendPin()
      return true
    } catch (error) {
      toast.displayError(error)
    }
  }

  const reset = () => {
    Object.assign(state, getInitialState())
  }

  return {
    ...toRefs(state),
    profileUsername,
    setRouter,
    login,
    signup,
    logout,
    verifyPin,
    resendPin,
    reset,
  }
})
