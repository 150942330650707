// src/router/admin-routes.js
export default [
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/AdminView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/activities',
    name: 'admin-activities',
    component: () => import('@/pages/admin/FitnessActivities.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/attempts',
    name: 'admin-attempts',
    component: () => import('@/pages/admin/Attempts.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/attempts/resolve',
    name: 'admin-attempts-pending',
    component: () => import('@/pages/admin/AttemptsResolver.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/activity/create',
    name: 'admin-activity-create',
    component: () => import('@/pages/admin/FitnessActivityCreate.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/activity/:id',
    name: 'admin-activity',
    component: () => import('@/pages/admin/FitnessActivityUsers.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/activity/:id/users',
    name: 'admin-activity-users',
    component: () => import('@/pages/admin/FitnessActivityUsers.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/activity/:fitnessActivityId/user/:userId/attempts',
    name: 'admin-activity-user-attempts',
    component: () => import('@/pages/admin/FitnessActivityUserAttempts.vue'),
    meta: { requiresAuth: true },
  },
]
