import { defineStore } from 'pinia'
import { reactive, toRefs } from 'vue'
import { useLoadingStore } from './loading-store'
import { useToastStore } from './toast-store'
import { useProfileStore } from './profile-store'
import type { ContactUs } from '@/types/ContactUs'
import userService from '@/services/user-service'
import { useAuthUserStore } from './auth-store'
import type { ForgotPassword } from '@/types/ForgotPassword'
import type { UserStats } from '@/types/UserStats'
import type { User } from '@/types/User'
import type { Attempt } from '@/types/Attempt'

export const useUserStore = defineStore('user-store', () => {
  const loading = useLoadingStore()
  const profileStore = useProfileStore()
  const toast = useToastStore()
  const authStore = useAuthUserStore()

  const getInitialState = () => ({
    userStats: undefined as UserStats | undefined,
    user: undefined as User | undefined,
    feed: [] as Attempt[],
  })

  const state = reactive(getInitialState())

  const reset = () => {
    Object.assign(state, getInitialState())
  }

  const submitContactUs = async (payload: ContactUs) => {
    try {
      loading.toggleLoading('contact-form-submit-loading')
      const response = await userService.postContactUsForm(payload)
      toast.displaySuccess(response.data)
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('contact-form-submit-loading')
    }
  }

  const submitForgotPassword = async (payload: ForgotPassword) => {
    try {
      loading.toggleLoading('forgot-password-submit-form-loading')
      const response = await userService.postForgotPasswordForm(payload)
      toast.displaySuccess(response)
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('forgot-password-submit-form-loading')
    }
  }

  const me = async () => {
    try {
      const response = await userService.me()
      authStore.isAuthenticated = true
      authStore.authUser = response.data
      if (authStore.authUser) profileStore.profile = authStore.authUser.profile
    } catch (error) {
      toast.displayError(error)
      authStore.isAuthenticated = false
    }
  }

  const getUserStats = async (userId: string) => {
    try {
      loading.toggleLoading('user-stats-loading')
      const response = await userService.fetchUserStats(userId)
      state.userStats = response.data
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('user-stats-loading')
    }
  }

  const getUserFeed = async (id: string) => {
    try {
      loading.toggleLoading('user-feed-loading')
      const response = await userService.fetchUserFeed(id)
      state.feed = response.data
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('user-feed-loading')
    }
  }

  const getUser = async (id: string) => {
    try {
      loading.toggleLoading('user-details-loading')
      const response = await userService.fetchPublicUser(id)
      state.user = response.data
    } catch (error) {
      toast.displayError(error)
    } finally {
      loading.toggleLoading('user-details-loading')
    }
  }

  return {
    ...toRefs(state),
    me,
    getUserStats,
    getUserFeed,
    submitContactUs,
    getUser,
    submitForgotPassword,
    reset,
  }
})
