import { getActivePinia, type Store } from 'pinia'

export function resetAllStores() {
  const pinia = getActivePinia()
  if (!pinia) {
    throw new Error('There is no active Pinia instance')
  }

  // Type assertion to access the stores
  const stores = (pinia as any)._s as Map<string, Store>

  stores.forEach((store) => {
    if (typeof (store as any).reset === 'function') {
      // Call the custom reset method if it exists
      (store as any).reset()
    } else {
      // Fallback to the generic reset if no custom reset method is available
      resetSetupStore(store)
    }
  })
}

function resetSetupStore(store: Store) {
  const initialState = JSON.parse(JSON.stringify(store.$state))
  store.$patch((state: any) => {
    Object.assign(state, initialState)
  })
}
