<template>
  <Transition name="toast">
    <SuccessToast
      v-if="toastStore.toast.type === 'success' && toastStore.showNotification"
      :key="'success-toast'"
      :title="toastStore.toast.title"
      :message="toastStore.toast.message" />
  </Transition>

  <Transition name="toast">
    <ErrorToast
      v-if="toastStore.toast.type === 'error' && toastStore.showNotification"
      :key="'error-toast'"
      :title="toastStore.toast.title"
      :message="toastStore.toast.message" />
  </Transition>

  <Transition name="toast">
    <WarningToast
      v-if="toastStore.toast.type === 'warning' && toastStore.showNotification"
      :key="'warnign-toast'"
      :title="toastStore.toast.title"
      :message="toastStore.toast.message" />
  </Transition>
</template>

<script setup lang="ts">
  import { Transition } from 'vue'
  import SuccessToast from './SuccessToast.vue'
  import ErrorToast from './ErrorToast.vue'
  import { useToastStore } from '../../../stores/toast-store'
  import WarningToast from './WarningToast.vue'
  const toastStore = useToastStore()
</script>

<style>
  .toast-enter-from {
    opacity: 0;
    transform: translateY(-60px);
  }
  .toast-enter-to {
    opacity: 1;
    transform: translateY(0);
  }
  .toast-enter-active {
    transition: all 0.3s ease;
  }
  .toast-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
  .toast-leave-to {
    opacity: 0;
    transform: translateY(-60px);
  }
  .toast-leave-active {
    transition: all 0.3s ease;
  }
</style>
