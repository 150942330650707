import { defineStore } from 'pinia'
import { reactive, toRefs } from 'vue'

export interface Toast {
  title: string
  message: string
  type: 'success' | 'error'
}

export const useToastStore = defineStore('toast-store', () => {
  const state = reactive({
    delay: 2000,
    showNotification: false,
    toast: { title: '', message: '', type: 'success' } as Toast,
  })

  const display = (payload: Toast, delay: number = state.delay) => {
    state.toast = payload
    state.showNotification = true
    setTimeout(() => (state.showNotification = false), delay)
  }

  const displaySuccess = (
    message: string = 'Action performed successfully',
    delay?: number
  ) => {
    display({ title: 'Success', message, type: 'success' }, delay)
  }

  const displayError = (
    error: any,
    defaultMessage: string = 'Something Went Wrong',
    delay?: number
  ) => {
    let errorMessage = defaultMessage

    if (Array.isArray(error?.errors)) {
      errorMessage = error.errors[0]
    } else if (Array.isArray(error)) {
      errorMessage = error[0]
    } else if (typeof error?.data?.message === 'string') {
      errorMessage = error.data.message
    } else if (typeof error?.message === 'string') {
      errorMessage = error.message
    }

    display({ title: 'Error', message: errorMessage, type: 'error' }, delay)
  }

  const reset = () => {
    state.showNotification = false
    state.toast = { title: '', message: '', type: 'success' }
  }

  return { 
    ...toRefs(state),
    display, 
    displayError, 
    displaySuccess,
    reset
  }
})
