// capacitor-http-client.ts
import { useAuthUserStore } from '@/stores/auth-store'
import { CapacitorHttp, type HttpParams, type HttpResponse } from '@capacitor/core'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

const getStoredToken = async (): Promise<string | null> => {
  try {
    const { value } = await SecureStoragePlugin.get({ key: 'token' })
    return value
  } catch (error) {
    console.warn('Error retrieving token from secure storage:', error)
    return null
  }
}

const createHeaders = async (contentType: string = 'application/json'): Promise<Record<string, string>> => {
  const headers: Record<string, string> = { 'Content-Type': contentType }
  const token = await getStoredToken()
  if (token) headers['Authorization'] = `Bearer ${token}`
  return headers
}

const handleResponse = async (response: HttpResponse): Promise<HttpResponse> => {
  if (response.status === 401 || response.status === 403) {
    useAuthUserStore().isAuthenticated = false
  }
  if (response.status >= 400) throw response.data
  return response
}

const request = async (options: any): Promise<HttpResponse> => {
  options.headers = await createHeaders(options.data instanceof FormData ? 'multipart/form-data' : 'application/json')
  options.url = `${API_BASE_URL}${options.url}`
  return handleResponse(await CapacitorHttp.request(options))
}

export const get = <T>(url: string, params?: HttpParams) => request({ method: 'GET', url, params })
export const post = <T>(url: string, data?: any) => request({ method: 'POST', url, data })
export const put = <T>(url: string, data: any) => request({ method: 'PUT', url, data })
export const del = <T>(url: string) => request({ method: 'DELETE', url })
export const postFormData = <T>(url: string, data: FormData) => request({ method: 'POST', url, data })
